d
<template>
    <div id="content" class="">
        <div class="bar-actions">
            <Button bType="create" :bCallback="createTemplate" bLabel="button.add_template"></Button>
        </div>

        <div class="designer-container">
            <div clas="nav">
                <select v-if="!new_template" @change="select($event)">
                    <option v-for="(template, index) in sortedTemplates" :value="template.id">{{ template.name }}</option>
                </select>
                <Button v-else bType="cancel" :bCallback="cancel" bLabel="menu_aux.action.cancel"></Button>

                <Button bType="save" customClass="btn-action" :bCallback="saveTemplate" bLabel="menu_aux.action.save"></Button>
            </div>

            <!-- {{ template }} -->

            <div class="fields">
                <input class="name" type="text" v-model="template.name" />
                <div class="field" v-for="(field, i) in template.fields" :key="i">
                    <input type="text" v-model="field.name" />
                    <select v-model="field.class">
                        <option value="date">Caducidad</option>
                        <option value="rec">Recepción</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Preview from '@/components/domain/label/preview'

import { Api } from '@/core/api.js'
const api = new Api()

export default {
    name: 'ConfigTemplate',
    components: { Preview },
    props: {},
    data() {
        return {
            templates: {},
            template: false,
            new_template: false
        }
    },
    computed: {
        sortedTemplates() {
            return Object.values(this.templates).sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
        }
    },
    created() {
        this.load()
    },
    methods: {
        load() {
            var self = this

            this.$overlay.loading()

            api.get('admin/printer/label/templates/ing').then(function (response) {
                self.templates = response.data

                var tpl
                if (Object.values(self.templates).length > 0) {
                    tpl = Object.values(self.templates)[0]
                } else {
                    tpl = {
                        id: 0,
                        name: '',
                        fields: {}
                    }
                }
                self.format(tpl)
                self.$overlay.hide()
            })
        },

        select(event) {
            this.format(this.templates[event.target.value])
        },

        format(original) {
            var tpl = JSON.parse(JSON.stringify(original))

            var count = 1
            const total = 11

            tpl.fields = Object.values(tpl.fields)

            for (var i in tpl.fields) {
                var field = tpl.fields[i]
                field.class = field.keyname.includes('rec') ? 'rec' : 'date'
                count++
            }

            while (count <= total) {
                var field = {
                    id: count,
                    name: '',
                    order: count,
                    class: 'date'
                }
                tpl.fields.push(field)
                count++
            }

            this.template = tpl
        },
        cancel() {
            this.new_template = false
            this.format(Object.values(this.templates)[0])
        },
        createTemplate() {
            this.new_template = true
            this.format({
                id: 0,
                name: '',
                fields: {}
            })
        },
        saveTemplate() {
            var self = this
            // console.log(this.template)
            var result = {
                name: this.template.name,
                id: this.template.id,
                fields: []
            }

            for (var i in this.template.fields) {
                var field = this.template.fields[i]
                var f = {
                    name: field.name,
                    order: field.order,
                    class: field.class,
                    id: field.id
                }
                result.fields.push(f)
            }

            this.$overlay.loading()
            api.post('admin/printer/label/templates/update', { template: JSON.stringify(result) }).then(function (response) {
                self.new_template = false
                self.$overlay.hide()
                self.templates = {}
                self.load()
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#content {
    padding-top: 100px;

    select {
        background-color: $color-white !important;
        -webkit-appearance: auto !important;
        padding: 10px;
    }

    .designer-container {
        padding: 20px 50px;

        .button {
            display: inline-block;
            background: $color-white;
            padding: 5px 10px;
            text-align: center;
            margin-right: 5px;
            border-radius: 3px;
            font-size: 14px;
            cursor: pointer;
        }
        .fields {
            margin-top: 20px;
            max-width: 500px;
            padding: 0;

            select,
            input {
                background-color: $color-white !important;
                margin-right: 10px;
                height: 35px;
                padding: 0px 15px;
                width: calc(65% - 10px);
            }
            select {
                width: 35%;
                margin-right: 0;
            }

            .name {
                width: 100%;
                margin-bottom: 10px;
                margin-top: 20px;
            }
            .field {
                padding: 5px 0;
                margin-bottom: 0px;
            }
        }
    }
}
</style>
